.loginWrapper {
  margin-inline: auto;
  min-width: 20rem;
  max-width: 60rem;
  padding-block: 1rem;
}

.loginWrapper h1 {
  font-size: 1.25rem;
  font-weight: 600;
  /* margin-block: 1rem; */
  margin-bottom: 0;
}

.loginWrapper h4 {
  font-weight: bold;
  margin: 10px 0;
}

.loginWrapper a {
  background-color: var(--green-400);
  color: var(--color-light);
  display: block;
  padding: 0.75rem 0.5rem;
  text-align: center;
  border-radius: 4px;
}

.loginWrapper a:hover {
  background-color: var(--green-300);
}

.error {
  color: red;
  margin-top: 0.5rem;
  max-width: 20rem;
}
