.formWrapper form input {
  /* border: 1px solid var(--color-border); */
  border: none;
  display: block;
  /* margin-block: 0.5rem; */
  padding: 0.5rem 1rem;
  width: 100%;
  align-self: stretch;
  background-color: transparent;
}

.formWrapper form input:hover,
.formWrapper form input:focus {
  border-color: var(--blue-400);
  outline: none;
}

.formWrapper form button {
  background-color: var(--blue-400);
  color: var(--color-light);
  border: 1px solid var(--blue-300);
  /* padding: 0.5rem; */
  padding: 6px 12px;
  border-radius: 4px;

  font-size: 14px;

  display: flex;
  align-items: center;
  gap: 5px;
}

.formWrapper form button:hover {
  background-color: var(--blue-300);
}

.inputWrapper {
  display: flex;
  align-items: center;

  /* background-color: var(--blue-400); */
  border: 1px solid var(--color-border);
  border-radius: 4px;

  margin-bottom: 10px;
}

.inputIcon {
  width: 43px;
  height: 34px;
  padding: 6px 12px;

  background-color: var(--blue-400);
  /* border-radius: 4px 0 0 4px; */
  border-radius: 3px 0 0 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  fill: #FFFFFF;
  stroke: #FFFFFF;
}

.inputIcon svg {
  width: 18px;
  height: 14px;
}

.icon {
  /* width: 16px; */
  /* height: 16px; */
  width: 14px;
  height: 14px;
  stroke: #FFFFFF;
  fill: #FFFFFF;
}